import React from 'react'

class CountSelector extends React.Component {
  constructor(props)
  {
    super(props)
    this.state =  {
        count : props.count,
        values : ["5","10","20","50","100"]
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({count: event.target.value});
    this.props.onUpdated(event.target.value);
  }

  renderValues()
  {
      return this.state.values.map(myvalue => {
          return (<option key={myvalue} value={myvalue}>{myvalue}</option>);
      });
  }
  render() {
    return (
      <div className="countselector">
        <label>
        Aantal oefeningen:
        <select value={this.state.count} onChange={this.handleChange}>
            {this.renderValues()}
        </select>
        </label>
      </div>
    );
  }
}

export default CountSelector;
