import React from 'react'
import dateFormat from 'dateformat'
import Duration from "./Duration"

class Report extends React.Component
{
  drawFailedExcercises()
  {
    return this.props.failedExercises.map(exercise => {
      return (<div className="exercise" key={exercise.index}>
                      <div className="exercise-left">{exercise.left}</div>
                      <div className="exercise-operator">{exercise.operator}</div>
                      <div className="exercise-right">{exercise.right}</div>
                      =
                      <div className="exercise-result">{exercise.result}</div>
                      <div className="exercise-fout">Foute antwoord: ({exercise.filledIn})</div>
              </div>);
    });
  }
  

  render() {
    return (
      <div className="report">
        <h1>Resultaten</h1>
        <div className="report-time">Tijdstip: {dateFormat(this.props.time,"dd/mm/yyyy HH:MM")}</div>
        <div className="report-duration">Duurtijd: <Duration duration={this.props.duration} /></div>
        <div className="report-correct">Oefeningen juist: {this.props.correct}</div>
        <div className="report-fouten">Oefeningen fout: {this.props.errors}</div>
        <div className="wrong">
          {this.drawFailedExcercises()}
        </div>
      </div>
    );
  }
}

export default Report;
