import React from 'react'

class Exercises extends React.Component {
constructor(props)
{
  super(props);

  this.state = {
    exercises: []
  }

  this.storeValue = this.storeValue.bind(this);
  this._handleKeyPress = this._handleKeyPress.bind(this);
}

componentWillMount()
{
  this.createExercises();
}

componentDidMount() {

  for (let x in this.refs) {
    this.refs[x].onkeypress = (e) => 
      this._handleKeyPress(e, this.refs[x]);
  }
  
}

createExercises()
{
  let exercises = [];
  let allExercises = this.getAllExcercisesForSelectedTables();

  // We need to make sure that we have enough exercises as was selected in the UI.
  // This means that we are going to start duplicating excercises if there are not enough in there.
  for (var i = 0; i < Math.max(this.props.amount,allExercises.length); i++)
  {
    // Copy items of the source array in to the result.
    let exercise = allExercises[(i % allExercises.length)];
     exercises.push(exercise);
  }

  // Now shuffle the exercises.
  exercises = this.shuffle(exercises);

  let result = [];
  console.log("creating exercises = " + this.props.amount);
  // Now I want to reset the index of the exercises so the index is the same as the position in this array.
  for (i = 0; i < this.props.amount; i++)
  {
    // All exercises must have a unique index.
    let exercise = exercises[i];
    exercise.index = i;
    result.push({ index:i, 
                  left: exercise.left, 
                  right: exercise.right, 
                  operator: exercise.operator, 
                  result: exercise.result});
  }
  this.setState({exercises: result});
}

getAllExcercisesForSelectedTables()
{
  let tables = this.props.tables;
  let multiplications = this.props.multiplications;
  let divisions = this.props.divisions;

  let exercises = [];
  tables.forEach(function(table)
  {
        let tableValue = table.value;

        for(var i=0;i<=10;i++)
        {
          if(multiplications)
          {
            exercises.push(
            {
              index: exercises.length,
              left: i,
              operator : "x",
              right : tableValue,
              result : i * tableValue
            });
          }
          if(divisions && tableValue !== 0)
          {
            exercises.push(
            {
              index: exercises.length,
              left: (i*tableValue),
              operator : ":",
              right : tableValue,
              result : i
            });
          }
        }
  });
  return exercises;
}

shuffle(array)
{
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

storeValue(event, index)
{
  let exercises = this.state.exercises;

  exercises.map(exercise => {
    if(exercise.index === parseInt(event.target.name,10))
    {
      exercise.filledIn = parseInt(event.target.value);
    }
    return exercise;
  });
  this.setState({exercises: exercises});
}

_handleKeyPress(e, field) {
  // If enter key is pressed, focus next input field.
  // This function is crappy but the best I could do for now. It seems to work.
  if (e.keyCode === 13) {
    e.preventDefault();
    console.log("fieldname =" + field.name);
    let currentIndex = parseInt(field.name);

    let selectedIndex = -1;
    let foundIndex = false;
    this.state.exercises.map(exercise => {
      
      if(selectedIndex !== -1 && foundIndex === false)
      {
        foundIndex = true;
        selectedIndex = exercise.index;
      }

      if(exercise.index === currentIndex)
      {
        selectedIndex = currentIndex;
      }
      return selectedIndex;
    });

    // If an index is found, use it to focus another field.
    if(foundIndex)
    {
      let element = document.getElementById(selectedIndex);
      if(element)
      {
        element.focus();
        return;
      }
    }
    else
    {
      let element = document.getElementById("readybutton");
      if(element)
      {
        element.focus();
      }
    }
  }
 }

drawExcercises()
{
  return this.state.exercises.map(exercise => {
    return (<div className="exercise" key={exercise.index}>
              
                    <div className="exercise-left">{exercise.left}</div>
                    <div className="exercise-operator">{exercise.operator}</div>
                    <div className="exercise-right">{exercise.right}</div>
                    =
                    <div className="exercise-result"><input id={exercise.index} 
                                                            name={exercise.index} type="number" 
                                                            ref={exercise.index}
                                                            inputMode="numeric" pattern="[0-9]*"  
                                                            onChange={this.storeValue.bind(this)}/></div>
            </div>);
  });
}
  render() {
    return (
      <div className="exercises">
        {this.drawExcercises()}
        <input id="readybutton" type="button" className="readybutton" value="Klaar" onClick={() => this.props.onDone(this.state.exercises)} />
      </div>
    );
  }
}

export default Exercises;
