import React from 'react'

class RestartButton extends React.Component {

  render() {
    return (
      <input type="button" className="restartbutton" value="Opnieuw beginnen" onClick={this.props.onClick} />
    );
  }
}

export default RestartButton;
