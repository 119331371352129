import React from 'react'
import PropTypes from 'prop-types'

const Table = ({ text, checked, onClick }) => (
  <div className="table"
  >
    <label>Tafel van {text}<input type="checkbox" onClick={onClick} checked={checked} onChange={()=>{}} /></label>
  </div>
)

Table.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.number.isRequired
}

export default Table
