import React from 'react'

import Table from "./Table";
import SelectAllTables from "./SelectAllTables";

class TableSelector extends React.Component {

  checkTable(id)
  {
    let newtables = this.props.tables;
    newtables.map(mytable =>
      {
        if(mytable.id === id)
        {
          // Found the table
          mytable.checked = !mytable.checked
        }
        return mytable;
      });
      this.props.onTablesSelected(newtables);

  }

  selectAllTables()
  {
    let newtables = this.props.tables;
    newtables.map(mytable =>
      {
        return mytable.checked = true;
      });
    //this.setState({tables: newtables});
    this.props.onTablesSelected(newtables);
  }
  renderTables()
  {
    return this.props.tables.map(mytable =>
      {
        return (<Table key={mytable.id} text={mytable.value} checked={mytable.checked} onClick={this.checkTable.bind(this, mytable.id)} />);
      }
    );
  }

  render() {
    return (
      <div className="tableselector">
        {this.renderTables()}
        <SelectAllTables onClick={this.selectAllTables.bind(this)} />
      </div>
    );
  }
}

export default TableSelector;
