import React from 'react'

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <h1>De tafels (deeltafels en maaltafels)</h1>
      </div>
    );
  }
}

export default Header;
