import React from 'react'


function Hours(props) {
  if (props.hours > 0) {
    return "Uren: " +props.hours + ",";
  }
  else
  {
    return "";
  }
}
function Minutes(props)
{
  if (props.minutes > 0 || props.hours > 0) {
    return "Minuten: " +props.minutes + ",";
  }
  else
  {
    return "";
  }
}
function Seconds(props)
{
  return "Seconden: "+ props.seconds;
}
class Duration extends React.Component
{
  constructor(props)
  {
    super(props);
    
    let durationInSeconds = Math.round(this.props.duration / 1000);
    let durationHours =  Math.floor(durationInSeconds / 3600);
    let rest = durationInSeconds - (durationHours * 3600);
    let durationMinutes = Math.floor(rest / 60);
    rest = rest - (durationMinutes * 60);
    let durationSeconds = rest;

    this.state = {
      hours: durationHours,
      minutes : durationMinutes,
      seconds: durationSeconds
    }
  }

  render() {
    return (
      <div className="duration">
        <Hours hours={this.state.hours} />
        <Minutes hours={this.state.hours} minutes={this.state.minutes} />
        <Seconds seconds={this.state.seconds} />
      </div>
    );
  }
}

export default Duration;
