import React, { Component } from 'react';
import Sky from "react-sky";

import './App.css';
import Header from "./components/Header";
import TableSelector from "./components/TableSelector";
import MultiplyAndDivisionSelector from "./components/MultiplyAndDivisionSelector";
import CountSelector from "./components/CountSelector";
import StartButton from "./components/StartButton";
import Exercises from "./components/Exercises";
import Report from "./components/Report";
import RestartButton from "./components/RestartButton";

class App extends Component {
  constructor()
  {
    super();
    this.state =  {
        tables: [
          {
            id : "table0",
            value: 0,
            checked: false
          },
          {
            id : "table1",
            value: 1,
            checked: true
          },
          {
            id : "table2",
            value: 2,
            checked: false
          },
          {
            id : "table3",
            value: 3,
            checked: false
          },
          {
            id : "table4",
            value: 4,
            checked: false
          },
          {
            id : "table5",
            value: 5,
            checked: false
          },
          {
            id : "table6",
            value: 6,
            checked: false
          },
          {
            id : "table7",
            value: 7,
            checked: false
          },
          {
            id : "table8",
            value: 8,
            checked: false
          },
          {
            id : "table9",
            value: 9,
            checked: false
          },
          {
            id : "table10",
            value: 10,
            checked: false
          }
        ],
        selectMultiplications: true,
        selectDivisions: true,
        count : 10,
        started : false,
        startTime: -1,
        report :
        {
          correct: 0,
          errors : 0,
          reportReady : false,
          duration: 0
        }

    }

    this.handleTablesSelected = this.handleTablesSelected.bind(this);
    this.handleTypeUpdated = this.handleTypeUpdated.bind(this);
    this.handleCountUpdated = this.handleCountUpdated.bind(this);
    this.onStartClicked = this.onStartClicked.bind(this);
    this.getSelectedTables = this.getSelectedTables.bind(this);
    this.onExercisesDone = this.onExercisesDone.bind(this);
    this.restart = this.restart.bind(this);
  }

  handleTablesSelected(updatedtables)
  {
    this.setState({tables: updatedtables});
  }

  handleTypeUpdated(selectMultiplications, selectDivisions)
  {
    this.setState({selectMultiplications: selectMultiplications, selectDivisions: selectDivisions});
  }

  handleCountUpdated(newCount)
  {
    this.setState({count: newCount});
    console.log("count set to " + newCount);
  }

  onStartClicked()
  {
    this.setState({started:true, startTime: Date.now()});
  }

  getSelectedTables()
  {
    let selectedTables = this.state.tables.filter(x =>  {
      return x.checked;
    });
    console.log(selectedTables);
    return selectedTables;
  }

  onExercisesDone(exercises)
  {

    let errorCount = 0;
    let correctCount = 0;
    let startTime = this.state.startTime;
    let failedExercises = [];

    exercises.forEach(function(exercise)
    {
        if(exercise.result === exercise.filledIn)
        {
          correctCount += 1;
        } 
        else {
          errorCount += 1;
          failedExercises.push({
                                  result: exercise.result, 
                                  filledIn: exercise.filledIn, 
                                  left: exercise.left, 
                                  right: exercise.right, 
                                  operator: exercise.operator
                                });
        }
    });

    this.setState({
                    report:
                    {
                      reportReady: true,
                      correct: correctCount,
                      errors: errorCount,
                      failedExercises: failedExercises,
                      duration: (Date.now() - startTime)
                    }
                  });

    console.log(exercises);
  }

  renderExcercises()
  {
    if(this.state.started && !this.state.report.reportReady)
    {
      return (<Exercises  amount={this.state.count}
                          multiplications={this.state.selectMultiplications}
                          divisions={this.state.selectDivisions}
                          tables={this.getSelectedTables()}
                          onDone={this.onExercisesDone}
                          />)
    }
  }



  renderSelection()
  {
    if(!this.state.started)
    {
      return (<div className="SelectionPanel">
        <TableSelector tables={this.state.tables} onTablesSelected={this.handleTablesSelected} />
        <MultiplyAndDivisionSelector  onUpdated={this.handleTypeUpdated} />
        <CountSelector count={this.state.count} onUpdated={this.handleCountUpdated} />
        <StartButton onClick={this.onStartClicked} />
      </div>);
    }
  }

  restart()
  {
    this.setState({
                    started: false,
                    report: {reportReady:false}
                  });
  }
  renderReport()
  {
    if(this.state.report.reportReady)
    {
      return (<div>
                <Report correct={this.state.report.correct}
                        errors={this.state.report.errors}
                        duration={this.state.report.duration}
                        failedExercises={this.state.report.failedExercises}
                        time={this.state.startTime} />
                <RestartButton onClick={this.restart} />
              </div>);
    }
  }

  render() {
    return (
      <div className="App">
        <Sky images={{
            /* FORMAT AS FOLLOWS */
            0: "./images/0.png",  /* You can pass as much images as you want */
            1: "./images/1.png",
            2: "./images/36div8.png",
            3: "./images/5.png",
            4: "./images/6.png",
            5: "./images/7.png",
            6: "./images/9.png",
            7: "./images/3x2.png",
            8: "./images/equal.png",
            9: "./images/plus.png"
          }}
          how={9} /* You have to pass a number so Sky will render that amount of images chosen randomly from the object you passed in the previous step */
          time={120} /* time of the animation. Dfaults at 20s */
          size={'50px'} /* size of the rendered images. Defaults at 150px */
          //background={'palettedvioletred'} /* color of background. Defaults to none */
        />
        <Header />
        {this.renderSelection()}
        {this.renderExcercises()}
        {this.renderReport()}
      </div>
    );
  }
}

export default App;
