import React from 'react'

class StartButton extends React.Component {

  render() {
    return (
      <input type="button" className="startbutton" value="Start" onClick={this.props.onClick} />
    );
  }
}

export default StartButton;
