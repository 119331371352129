import React from 'react'

class MultiplyAndDivisionSelector extends React.Component {
  constructor(props)
  {
    super(props)

    this.state =
    {
      selectMultiplications: true,
      selectDivisions: true
    }
  }

  checkMultiplications()
  {
      let selectMultiplications = !this.state.selectMultiplications;
      let selectDivisions = this.state.selectDivisions;

      if(!selectMultiplications)
      {
        selectDivisions = true;
      }

      this.setState({
                      selectMultiplications: selectMultiplications,
                      selectDivisions: selectDivisions,
                    });

    this.props.onUpdated(selectMultiplications, selectDivisions);
  }
  checkDivisions()
  {
    let selectMultiplications = this.state.selectMultiplications;
    let selectDivisions = !this.state.selectDivisions;

    if(!selectDivisions)
    {
      selectMultiplications = true;
    }


    this.setState({
                    selectMultiplications: selectMultiplications,
                    selectDivisions: selectDivisions,
                  });
    this.props.onUpdated(selectMultiplications, selectDivisions);
  }

  render() {
    return (
      <div className="multiplyanddivisionselector">
        <div className="tabletypeselector"><label>Maaltafels:<input type="checkbox" id="showMultiplications" checked={this.state.selectMultiplications} onClick={this.checkMultiplications.bind(this)} onChange={()=>{}} /></label></div>
        <div className="tabletypeselector"><label className="tabletypeselector">Deeltafels:<input type="checkbox" id="showDivisions" checked={this.state.selectDivisions} onClick={this.checkDivisions.bind(this)} onChange={()=>{}} /></label></div>
      </div>
    );
  }
}

export default MultiplyAndDivisionSelector;
