import React from 'react'
import PropTypes from 'prop-types'

const SelectAllTables = ({ onClick }) => (
  <div className="selectalltables"
    onClick={onClick}
  >
    <label><button onClick={onClick}>Alle tafels</button></label>
  </div>
)

SelectAllTables.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default SelectAllTables
